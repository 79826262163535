<template>
    <b-popover target="popover-roles" triggers="hover focus">
        <template slot="title">Hiérarchie des permissions</template>
        <p class="mb-0">
            <em>Public</em> = Utilisateur non connecté
        </p>
        <p class="mb-0">
            <em>Utilisateur labmda</em> = Tout utilisateur avec un compte valide (email validé)
        </p>
        <p class="mb-0">
            <em>Association valide</em> = Membre validé par le bureau (Cluji ou autre asso)
        </p>
        <p class="mb-0">
            <em>Membre du Cluji</em> = Membre du Cluji pour cette année
        </p>
        <p v-if="isBureau()">
            <em>Bureau du Cluji</em> = Au bureau du Cluji pour cette année
        </p>
    </b-popover>
</template>

<script>
    import {isBureau} from '@/util/auth';

    export default {
        name: "RolesPopoverWiki",
        methods: {isBureau}
    }
</script>
